// 3rd party imports
import * as React from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
// ui
import { useLocalization } from '@shared-ui/localization-context';
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutPosition } from 'uitk-react-layout-position';
import { UitkLink } from 'uitk-react-link';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkTertiaryButton } from 'uitk-react-button';
import { EventType, useCommonClickStreamEvent } from 'shared/utils';
import { SigninAndJoinNav } from 'shared/components/layout/signin-and-join-nav';
import { LanguageSelector } from 'shared/components/layout/language-selector';
import { facebookLeadEvent } from 'shared/utils/facebook-pixel-events';
var Header = function () {
  var l10n = useLocalization();
  var navItems = [{
    label: l10n.formatText('creatorvideos.common.benefits'),
    anchor: '#benefits'
  }, {
    label: l10n.formatText('creatorvideos.common.inspiration'),
    anchor: '#inspiration'
  }, {
    label: l10n.formatText('creatorvideos.common.how_it_works'),
    anchor: '#how-it-works'
  }, {
    label: l10n.formatText('creatorvideos.common.tips'),
    anchor: '#tips'
  }, {
    label: l10n.formatText('creatorvideos.common.FAQ'),
    anchor: '#faq'
  }];
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var pathname = useLocation().pathname;
  var history = useHistory();
  var handleAnchorClick = function (anchor, event) {
    var anchorText = anchor.replace('#', '');
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_redirect_to_section.selected',
      action_location: "header-nav-".concat(anchorText)
    }, {
      additional_context: {
        user_interface: {
          component_name: 'header-nav',
          component_element_text: anchorText
        }
      }
    });
    if (!(pathname.endsWith('travel-video') || pathname.endsWith('travel-video/'))) {
      event.preventDefault();
      history.push("/travel-video".concat(anchor));
    }
  };
  var signupUrlSearchParam = 'initial=travel_video';
  return React.createElement(UitkLayoutPosition, {
    type: "sticky",
    position: {
      top: 'zero',
      zindex: 'layer1'
    }
  }, React.createElement(GlobalNavigationSiteHeader, {
    className: "Header"
  }, React.createElement(UitkSpacing, {
    padding: {
      small: {
        inline: 'six'
      }
    }
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("div", {
    className: "CVHeader__logo"
  }, React.createElement(UitkLink, {
    onClickCapture: function () {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_sign_in_affiliate.selected',
        action_location: 'travel-video-landing-page-inspiration-button'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'inspiration button'
          }
        }
      });
      if (pathname.endsWith('travel-video') || pathname.endsWith('travel-video/')) {
        facebookLeadEvent([{
          content_name: 'Travel Video Header Logo'
        }]);
      }
    }
  }, React.createElement(Link, {
    to: '/travel-video'
  }, React.createElement("img", {
    src: "/images/shared/eg-affiliate-program-logo-blue.svg",
    alt: "Expedia Group Affiliate Program"
  }))))), React.createElement("div", {
    className: "show-desktop"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    space: "three",
    className: "TVHeader__nav"
  }, navItems.map(function (link, ind) {
    return React.createElement(UitkTertiaryButton, {
      onClick: function (event) {
        return handleAnchorClick(link.anchor, event);
      },
      key: "header-nav-".concat(ind)
    }, React.createElement("a", {
      href: link.anchor
    }, link.label));
  }))), React.createElement(UitkLayoutFlexItem, null, React.createElement("nav", {
    className: "Header__nav"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    className: "no-bullet",
    direction: "row",
    space: "three",
    tag: "ul",
    wrap: "wrap"
  }, React.createElement("li", null, React.createElement(LanguageSelector, null)), React.createElement("li", null, React.createElement(SigninAndJoinNav, {
    signupUrlSearchParam: signupUrlSearchParam,
    customSignInPath: "https://console.vap.expedia.com/eg-affiliates/products/travel-videos"
  })))))))));
};
export { Header };