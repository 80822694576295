var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];
      for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
    }
    return t;
  };
  return __assign.apply(this, arguments);
};
import { baseEventFragmentPayload, baseExperienceFragmentPayload, creatorVideoBaseEventFragmentPayload } from './click-stream-utils';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useCallback } from 'react';
export var EventType;
(function (EventType) {
  EventType["PAGE_VIEW"] = "Page View";
  EventType["INTERACTION"] = "Interaction";
  EventType["IMPRESSION"] = "Impression";
  EventType["OUTCOME"] = "Outcome";
})(EventType || (EventType = {}));
export var APP_NAME = 'eg-affiliates-homepage';
export var trackTravelCreatorHomeEvents = function (track, eventName, eventType, actionLocation) {
  var openEvent = {
    event: {
      event_version: '1.0.0',
      event_name: eventName,
      event_type: eventType,
      action_location: actionLocation
    },
    experience: {
      page_name: 'travel-creator',
      app_name: APP_NAME,
      app_version: '1.0.0'
    }
  };
  track(openEvent);
};
export var affiliateHubHomePresentedEvent = function (track) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_type: EventType.PAGE_VIEW,
      event_name: 'affiliates_hub_home.viewed'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: 'affiliate_hub_home',
      page_name_detailed: 'Affiliate Hub Home'
    })
  };
  track(openEvent);
};
export var unsubscribePresentedEvent = function (track) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_type: EventType.PAGE_VIEW,
      event_name: 'affhub_unsubscribe.viewed'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: 'affiliate_unsubscribe',
      page_name_detailed: 'Unsubscribe'
    })
  };
  track(openEvent);
};
export var unsubscribeSelectedEvent = function (track) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_type: EventType.INTERACTION,
      event_name: 'affhub_unsubscribe_request.selected'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: 'unsubscribe',
      page_name_detailed: 'Unsubscribe'
    })
  };
  track(openEvent);
};
export var unsubscribeSucceedEvent = function (track) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_type: EventType.OUTCOME,
      event_name: 'affhub_unsubscribe_request.succeeded'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: 'unsubscribe',
      page_name_detailed: 'Unsubscribe'
    })
  };
  track(openEvent);
};
export var unsubscribeFailedEvent = function (track) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_type: EventType.OUTCOME,
      event_name: 'affhub_unsubscribe_request.failed'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: 'unsubscribe',
      page_name_detailed: 'Unsubscribe'
    })
  };
  track(openEvent);
};
export var headerLogoSelectedEvent = function (track, page) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_name: 'affhub_header_logo.selected'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: page !== null && page !== void 0 ? page : baseExperienceFragmentPayload.page_name
    })
  };
  track(openEvent);
};
export var headerSigninSelectedEvent = function (track, page) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_name: 'affhub_header_sign_in.selected'
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: page !== null && page !== void 0 ? page : baseExperienceFragmentPayload.page_name
    })
  };
  track(openEvent);
};
export var homeJoinNowSelectedEvent = function (track, actionLocation) {
  var openEvent = {
    event: __assign(__assign({}, baseEventFragmentPayload), {
      event_name: 'affhub_home_join_now.selected',
      action_location: actionLocation
    }),
    experience: __assign(__assign({}, baseExperienceFragmentPayload), {
      page_name: 'affiliate_hub_home'
    })
  };
  track(openEvent);
};
var clickstreamEventBuilder = function (eventOverride, additionalContext) {
  var openEvent = __assign(__assign({
    event: __assign(__assign(__assign({}, creatorVideoBaseEventFragmentPayload), {
      action_reason: sessionStorage.getItem('userType')
    }), eventOverride)
  }, additionalContext && additionalContext), {
    booking: {
      order_id: sessionStorage.getItem('orderNumber'),
      pos_order_reference_number: sessionStorage.getItem('itinerary'),
      transaction_status: 'Success'
    },
    trip: {
      trip_detail_list: [{
        trip_status: sessionStorage.getItem('tripStatus')
      }]
    },
    product_list: [{
      product_id: sessionStorage.getItem('propertyId')
    }]
  });
  return openEvent;
};
export var useCommonClickStreamEvent = function () {
  var track = useEgClickstream();
  var trackEvent = useCallback(function (eventOverride, additionalContext) {
    try {
      var openEvent = clickstreamEventBuilder(eventOverride, additionalContext);
      track(openEvent);
    } catch (e) {
      // Analytics tracking as a side effect, should not break functionality if failed
    }
  }, [track]);
  return {
    trackEvent: trackEvent
  };
};