var __read = this && this.__read || function (o, n) {
  var m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  var i = m.call(o),
    r,
    ar = [],
    e;
  try {
    while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  } catch (error) {
    e = {
      error: error
    };
  } finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    } finally {
      if (e) throw e.error;
    }
  }
  return ar;
};
// 3rd party imports
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { LocalizedText } from '@shared-ui/localization-context';
// ui
import { UitkSecondaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkList, UitkListItem } from 'uitk-react-list';
import { UitkMenu, UitkMenuContainer, UitkMenuTrigger } from 'uitk-react-menu';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';
import { EventType, headerSigninSelectedEvent, useCommonClickStreamEvent } from 'shared/utils';
import { facebookLeadEvent } from 'shared/utils/facebook-pixel-events';
import { JoinNowAction } from './join-now-action';
var SigninAndJoinNav = function (_a) {
  var signupUrlSearchParam = _a.signupUrlSearchParam,
    customSignInPath = _a.customSignInPath;
  var track = useEgClickstream();
  var trackEvent = useCommonClickStreamEvent().trackEvent;
  var pathname = useLocation().pathname;
  var _b = __read(useState(false), 2),
    menuOpen = _b[0],
    setMenuOpen = _b[1];
  var handleMenuClick = function () {
    setMenuOpen(!menuOpen);
  };
  var handleSignInClick = function () {
    if (customSignInPath === null || customSignInPath === void 0 ? void 0 : customSignInPath.includes('travel-videos')) {
      trackEvent({
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_sign_in_affiliate.selected',
        action_location: 'travel-video-landing-page-header-sign-in'
      }, {
        additional_context: {
          user_interface: {
            component_element_text: 'Sign in Header'
          }
        }
      });
      if (pathname.endsWith('travel-video') || pathname.endsWith('travel-video/')) {
        facebookLeadEvent([{
          content_name: 'Travel Video Header Sign In'
        }]);
      }
    } else {
      headerSigninSelectedEvent(track);
    }
  };
  var signInUrl = customSignInPath ? customSignInPath : "/signin";
  return React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    className: "no-bullet",
    direction: "row",
    space: "three",
    tag: "ul"
  }, React.createElement("li", {
    className: "visible-desktop--large"
  }, React.createElement(UitkTertiaryButton, {
    onClickCapture: handleSignInClick
  }, React.createElement("a", {
    href: signInUrl,
    "data-cy": "header-sign-in-link"
  }, React.createElement(UitkText, {
    size: 300
  }, React.createElement(LocalizedText, {
    message: "common.sign_in"
  }))))), React.createElement("li", {
    className: "visible-desktop--large"
  }, React.createElement(JoinNowAction, {
    actionLocation: "header",
    signupUrlSearchParam: signupUrlSearchParam
  })), React.createElement("li", {
    className: "visible-mobile--large"
  }, React.createElement(UitkMenu, {
    isOpen: menuOpen,
    onTriggerClick: handleMenuClick
  }, React.createElement(UitkMenuTrigger, null, React.createElement(UitkTertiaryButton, {
    "data-stid": "mobile-nav-menu-btn"
  }, React.createElement(UitkIcon, {
    name: "account_circle",
    className: "Header__icon"
  }))), React.createElement(UitkMenuContainer, {
    autoPosition: true,
    className: "HeaderJoinNow__menu_item"
  }, React.createElement(UitkSpacing, {
    padding: {
      inline: 'four',
      blockstart: 'half',
      blockend: 'two'
    }
  }, React.createElement(UitkList, {
    bullet: "no-bullet",
    "data-stid": "mobile-nav-menu"
  }, React.createElement(UitkListItem, null, React.createElement(UitkLayoutFlex, {
    direction: "column"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement(JoinNowAction, {
    actionLocation: "header",
    signupUrlSearchParam: signupUrlSearchParam
  })))), React.createElement(UitkListItem, null, React.createElement(UitkSpacing, {
    padding: {
      inline: 'twelve'
    }
  }, React.createElement(UitkSecondaryButton, {
    tag: "a",
    href: signInUrl,
    isFullWidth: true,
    "data-cy": "header-sign-in-link",
    onClickCapture: handleSignInClick
  }, React.createElement("a", {
    href: signInUrl,
    "data-cy": "header-sign-in-link"
  }, React.createElement(UitkText, {
    size: 300
  }, React.createElement(LocalizedText, {
    message: "common.sign_in"
  }))))))))))));
};
export { SigninAndJoinNav };