// 3rd party imports
import * as React from 'react';
// components
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
// store
import { LanguageSelector } from './language-selector';
var HeaderLite = function () {
  return React.createElement(GlobalNavigationSiteHeader, null, React.createElement(UitkSpacing, {
    padding: {
      inline: 'four'
    }
  }, React.createElement(UitkLayoutFlex, {
    className: "container ErrorMessage__header",
    alignItems: "center",
    justifyContent: "space-between"
  }, React.createElement(UitkLayoutFlexItem, null, React.createElement("img", {
    className: "Header__logo",
    src: "/images/shared/eg-affiliate-program-logo-blue.svg",
    alt: "Expedia Group Affiliate Program"
  })), React.createElement(UitkLayoutFlexItem, null, React.createElement("nav", {
    className: "Header__nav"
  }, React.createElement(UitkLayoutFlex, {
    alignItems: "center",
    className: "no-bullet",
    direction: "row",
    space: "three",
    tag: "ul",
    wrap: "wrap"
  }, React.createElement("li", null, React.createElement(LanguageSelector, null))))))));
};
export default HeaderLite;
export { HeaderLite };